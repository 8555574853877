import {mapActions, mapGetters, mapMutations} from "vuex";

import {dynamicHead} from '@/mixins/dynamic-head.js'
import videoSlider from "@/modules/video/components/video-slider/index.vue";

export default {
  name: "main-video",
  mixins: [dynamicHead],
  components:{
    videoSlider
  },
  data(){
    return{
      params: {
        title: '',
        description: '',
        keywords: '',
        image: '',
      },
      crumbs: [
        {name: 'home', title: this.$t('mainLabel'), slug: ''},
        {name: 'video', title: this.$t('video'), slug: ''}
      ],
    }
  },
  watch: {
    '$store.getters.translation': {
      immediate: true,
      handler(newVal) {
        this.crumbs[0].title = newVal.mainLabel
        this.crumbs[1].title = newVal.video
      }
    },
  },
  created() {
    this.fetchMeta(this.$route.name).then(()=>{
      this.setParams()
      this.setMeta()
    }).catch(error=>{
      console.log(error)
    })
    this.fetchVideo()
  },
  computed:{
    ...mapGetters({
      meta: 'meta/meta',
      videos: 'home/video',
    })
  },

  methods:{
    ...mapActions({
      fetchMeta: 'meta/GET_META',
      fetchVideo: 'home/GET_VIDEO'
    }),
    ...mapMutations({
    }),
    setParams(){
      this.params.title = this.meta.metaTitle;
      this.params.description = this.meta.metaDescription;
      this.params.keywords = this.meta.metaKeywords;
      this.params.image = this.meta.metaImage;
    }
  }
}